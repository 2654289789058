const typographyPlugin = require('tailwind-utils/typography.plugin');
const fontSizeVariantsPlugin = require('tailwind-utils/fontSizeVariants.plugin');

const colorsConfig = require('./configs/tailwind.colors');
const typographyConfig = require('./configs/tailwind.typography');
const proseConfig = require('./configs/tailwind.prose');

const fontFamily = {
  primary: `var(--font-primary), Arial, sans-serif`,
  secondary: `Arial, sans-serif`,
};

/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      fontFamily,
      colors: colorsConfig,

      typography: proseConfig(typographyConfig),

      maxWidth: {
        tabloid: '980px',
      },
    },
  },
  safelist: [
    'text-black',
    'text-white',
    'text-red-600',
    'text-yellow-700',
    'text-blue-700',
    'text-primary-700',
    'bg-white',
    'bg-black',
    'bg-primary-700',
    'bg-blue-100',
    'bg-primary-100',
    'bg-yellow-100',
  ],

  plugins: [typographyPlugin(typographyConfig), fontSizeVariantsPlugin],
};
